/* eslint-disable no-restricted-syntax */

/**
 * Updates a link that points to another language (e.g '/fr') so that it redirects to
 * the other language's version of the current page (as specified in languageSelectors).
 * @param {string} id - the DOM element that contains the link (e.g. "NavigationList")
 * @param {string} language - the current language
 * @param {array} languageSelectors - languageSelectors from Sitecore routeData
 */
export const updateLanguageLinks = (language, languageSelectors) => {
  const element = document.getElementById('root');
  const targetLanguage = languageSelectors.find(
    selector => selector.code !== language,
  );

  const languageLinks = element.querySelectorAll(
    `a[href^='/${targetLanguage.code}']`,
  );

  if (languageLinks.length > 0) {
    languageLinks[0].href = targetLanguage.url;
  }
};

/**
 * Returns true if a mutation includes the addition or removal of a language selector link.
 * We're interested in both because it represents mobile nav being replaced by desktop,
 * and vice-versa.
 * @param {object} mutation - a mutation record
 */
const languageSelectorUpdated = mutation => {
  if (mutation.type !== 'childList') return false;
  const { addedNodes, removedNodes } = mutation;
  const added = addedNodes.length > 0 && addedNodes[0]?.innerHTML?.indexOf('LanguageSelector') > -1;
  const removed = removedNodes.length > 0 && removedNodes[0]?.innerHTML?.indexOf('LanguageSelector') > -1;

  return added || removed;
};

/**
 * Observes a DOM element for the addition of a NavigationMenu (in Acura current-state header)
 * @param {string} id - the DOM element to observe, e.g. "root"
 * @param {string} language - the current language
 * @param {array} languageSelectors - languageSelectors from Sitecore routeData
 */
const observeExternalNavigation = (language, languageSelectors) => {
  const element = document.getElementById('root');
  if (!element) return;

  const config = { childList: true, subtree: true };

  const callback = mutationsList => {
    for (const mutation of mutationsList) {
      if (languageSelectorUpdated(mutation)) {
        updateLanguageLinks(
          language,
          languageSelectors,
        );
      }
    }
  };
  const observer = new MutationObserver(callback);

  observer.observe(element, config);
};

export default observeExternalNavigation;
