/* eslint-disable no-script-url */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

const ApplicationErrorMessage = ({ is404 }) => {
  const linkPath = is404 ? {
    en: 'javascript:history.go(-1)',
    fr: 'javascript:history.go(-1)',
  } : {
    en: '/',
    fr: '/accueil',
  };
  const copy = is404 ? {
    en: 'to take a u-turn back to the previous page.',
    fr: 'pour faire demi-tour et retourner à la page précédente.',
  } : {
    en: 'to return to the Home page.',
    fr: 'pour revenir à la page d’accueil.',
  };

  useEffect(() => {
    if (is404) {
      const requestedPath = window.location.href;
      const referringPagePath = document.referrer || '';
      window.dataLayer.push({
        event: 'e_httpError',
        httpStatusCode: '404',
        referringPagePath,
        requestedPagePath: requestedPath,
      });
    }
  }, [is404]);

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        />
        <link
          rel="icon"
          href="https://www.acura.ca/favicon.ico"
        />
        <link
          href="https://www.acura.ca/favicon.ico"
          rel="shortcut icon"
          type="image/x-icon"
        />
        <title>Acura Canada</title>
      </Helmet>

      <div id="hui-layout-container">
        <div
          id="hui-content-container"
          style={{ margin: '0 auto', padding: '2% 7%' }}
        >
          <div
            id="hui-content-holder"
            style={{ background: '#ffffff' }}
          >
            <div
              id="error-text-container"
              style={{ margin: '0 auto', padding: '2% 0%' }}
            >
              <h1>ERROR</h1>
              <h2 style={{ color: '#DA2C2E' }}>This page cannot be found.</h2>
              <p>
                Sorry, we didn’t mean to lead you down the wrong track. The
                current Acura page you are looking for might have been removed,
                had its name changed or is temporarily unavailable.
              </p>
              <p>
                <a href={linkPath.en}>Click here</a>
                {' '}
                {copy.en}
              </p>
            </div>
            <div style={{ border: '1px silver solid' }} />
            <div id="error-text-container">
              <h1>ERREUR</h1>
              <h2 style={{ color: '#DA2C2E' }}>Cette page ne peut être trouvée.</h2>
              <p>
                Désolé, nous n’avions pas l’intention de vous diriger dans la
                mauvaise direction. La page Acura que vous cherchez n’existe
                peut-être plus, son nom a pu être changé ou elle peut être
                temporairement hors d’usage.
              </p>
              <p>
                <a href={linkPath.fr}>Cliquez ici</a>
                {' '}
                {copy.fr}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ApplicationErrorMessage.propTypes = {
  is404: PropTypes.bool,
};

export default ApplicationErrorMessage;
