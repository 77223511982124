/* eslint-disable no-underscore-dangle */
import 'es6-promise/auto';
import 'isomorphic-fetch';
import smoothscroll from 'smoothscroll-polyfill';

import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import isBrowserModern from '@honda-canada/sitecore-jss-common/lib/utils/isBrowserModern';
import i18nInit from '@honda-canada/sitecore-jss-common/lib/utils/i18nInit';
import AppRoot from '@honda-canada/sitecore-jss-common/lib/components/AppRoot';
import ErrorBoundary from '@honda-canada/sitecore-jss-common/lib/components/ErrorBoundary';

import { Provider as ReduxProvider } from 'react-redux';
import { store } from '@honda-canada/sitecore-jss-components/lib/core/store';

import ReducersProvider from '@honda-canada/sitecore-jss-components/lib/components/ReducersProvider';
import ModelExplorationContextProvider from '@honda-canada/sitecore-jss-components/lib/components/ModelExplorationContext';
import PopupContextProvider from '@honda-canada/sitecore-jss-components/lib/components/Popup/PopupContext';
import UserLocationModal from '@honda-canada/sitecore-jss-components/lib/components/UserLocationModal';
import UserLocationSummary from '@honda-canada/sitecore-jss-components/lib/components/UserLocationSummary';
import ModelFiltersContextProvider from '@honda-canada/sitecore-jss-components/lib/components/ModelFiltersContext';
import { AcuraTheme } from '@honda-canada/design-system-react/lib/acura-theme';
import UserLocationProvider from '@honda-canada/user-location';
import '@honda-canada/design-system-react/lib/acura-css.css';
import '@honda-canada/embedded-chat/lib/acura-ui.css';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { ThemeProvider } from 'styled-components';
import { GoogleMapsApiKeyProvider } from '@honda-canada/experience-api-client';
import TagManager from 'react-gtm-module';
import componentFactory from './componentFactory';
import observeExternalNavigation, { updateLanguageLinks } from './languageLinks';
import ApplicationErrorMessage from './ApplicationErrorMessage';

smoothscroll.polyfill();

let renderFunction = ReactDOM.render;

let initLanguage = 'en';

let __JSS_STATE__ = null;
const ssrRawJson = document.getElementById('__JSS_STATE__');

if (ssrRawJson) {
  __JSS_STATE__ = JSON.parse(ssrRawJson.innerHTML);
}

if (__JSS_STATE__) {
  renderFunction = ReactDOM.hydrate;
  initLanguage = __JSS_STATE__.sitecore.context.language;
}

const { config } = __JSS_STATE__;

const gtmDevParameters = new URLSearchParams(config.gtmDevParameters);
const tagManagerArgs = config.gtmDevParameters ? {
  gtmId: 'GTM-5LLMWRL',
  auth: gtmDevParameters && gtmDevParameters.get('gtm_auth'),
  preview: gtmDevParameters && gtmDevParameters.get('gtm_preview'),
} : {
  gtmId: 'GTM-5LLMWRL',
};

TagManager.initialize(tagManagerArgs);

const renderUserLocationAppInFooter = ({
  setReloadLocationFromCookies,
  setIsUserLocationModalOpen,
  reloadLocationFromCookies,
}) => {
  const locationRootEl = document.getElementById('province-dropdown');

  if (locationRootEl) {
    renderFunction(
      <ErrorBoundary
        errorMessage={<ApplicationErrorMessage />}
      >
        <ThemeProvider theme={AcuraTheme}>
          <GoogleMapsApiKeyProvider
            env={config?.hondaExperienceApiEnv}
            consumerId={config?.hondaExperienceApiConsumerId}
            consumerName={config?.hondaExperienceApiConsumerName}
          >
            {googleMapsApiKey => (
              <UserLocationProvider
                googleMapsApiKey={googleMapsApiKey}
                forceReload={reloadLocationFromCookies}
                onLocationChanged={() => {
                  setReloadLocationFromCookies(!reloadLocationFromCookies);
                }}
              >
                <UserLocationSummary
                  onChangeLocationClick={() => {
                    setIsUserLocationModalOpen(true);
                  }}
                />
              </UserLocationProvider>
            )}
          </GoogleMapsApiKeyProvider>
        </ThemeProvider>
      </ErrorBoundary>,
      locationRootEl,
    );
  }
};

const App = () => {
  const [reloadLocationFromCookies, setReloadLocationFromCookies] = useState(false);
  const [isUserLocationModalOpen, setIsUserLocationModalOpen] = useState(false);

  useEffect(() => {
    renderUserLocationAppInFooter({
      reloadLocationFromCookies,
      setIsUserLocationModalOpen,
      setReloadLocationFromCookies,
    });
  }, [reloadLocationFromCookies]);

  return (
    <ErrorBoundary
      errorMessage={<ApplicationErrorMessage />}
    >
      <ReduxProvider store={store}>
        <HelmetProvider>
          <GoogleMapsApiKeyProvider
            env={config?.hondaExperienceApiEnv}
            consumerId={config?.hondaExperienceApiConsumerId}
            consumerName={config?.hondaExperienceApiConsumerName}
          >
            {googleMapsApiKey => (
              <UserLocationProvider
                googleMapsApiKey={googleMapsApiKey}
                forceReload={reloadLocationFromCookies}
                onLocationChanged={() => setReloadLocationFromCookies(!reloadLocationFromCookies)}
                requestUserEnterLocation={() => setIsUserLocationModalOpen(true)}
              >
                <AppRoot
                  activePlaceholders={['acura-main']}
                  componentFactory={componentFactory}
                  config={config}
                  errorMessage={<ApplicationErrorMessage is404 />}
                  isUserLocationModalOpen={isUserLocationModalOpen}
                  mainPlaceholderName="acura-main"
                  modelExplorationContextProvider={ModelExplorationContextProvider}
                  popupContextProvider={PopupContextProvider}
                  modelFiltersContextProvider={ModelFiltersContextProvider}
                  path={window.location.pathname}
                  Router={BrowserRouter}
                  ReducersProvider={ReducersProvider}
                  setIsUserLocationModalOpen={setIsUserLocationModalOpen}
                  ssrState={__JSS_STATE__}
                  theme={AcuraTheme}
                  updateExternalLanguageLinks={(language, languageSelectors) => {
                    // observe DOM (i.e. current-state header) for addition of language links
                    observeExternalNavigation(language, languageSelectors);
                    // explicitly update language links on page load and route change
                    updateLanguageLinks(language, languageSelectors);
                  }}
                  userLocationModal={UserLocationModal}
                />
              </UserLocationProvider>
            )}
          </GoogleMapsApiKeyProvider>
        </HelmetProvider>
      </ReduxProvider>
    </ErrorBoundary>
  );
};

const renderApp = async () => {
  const dictionary = __JSS_STATE__?.viewBag?.dictionary;
  const rootElement = document.getElementById('jss-root');
  const languageSelectors = __JSS_STATE__?.sitecore?.context?.languageSelectors || [];

  await i18nInit(initLanguage, dictionary);

  updateLanguageLinks(initLanguage, languageSelectors);

  renderFunction(
    <App />,
    rootElement,
  );
};

if (isBrowserModern()) {
  renderApp();
} else {
  import('./polyfills').then(renderApp);
}
